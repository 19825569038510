<template>
  <div
    id="head"
    class="headWrap"
    :class="{
      toFixed: searchBarFixed == true,
      head_wrapBlue: headBg === '1',
      head_wrapGreen: headBg === '2',
      head_wrapMall: headBg === '3',
      head_wrapHIS: headBg === '4',
      head_wrapHealth: headBg === '5',
    }"
  >
    <div class="head_wrap">
      <div class="head-con">
        <div class="logoDiv">
          <a href="/">
            <img
              alt="致医健康"
              title="致医健康"
              class="showImg"
              src="@/assets/images/logo.png"
            />
          </a>
        </div>
        <div class="nav">
          <div class="nav-a">
            <ul class="nav-a-ul">
              <li
                class="nav-a-li"
                :class="classA == index ? 'active' : ''"
                v-for="(item, index) in configNav"
                :key="index"
              >
                <router-link
                  :to="item.path"
                  exact
                  class="nav-a-text"
                  @click="showToggle(index)"
                  >{{ item.name }}</router-link
                >
                <i v-if="item.subItems" class="iconArrow"></i>
                <ul class="menu_ul" :class="{ active: index === isShow }">
                  <li
                    class="menu_li"
                    v-for="(nav, index) in item.subItems"
                    :key="index"
                    :class="classB == nav ? 'active' : ''"
                    @click="menuselected(nav)"
                  >
                    <router-link
                      class="menu_ul_text"
                      :to="nav.link"
                      :class="{ active: nav.link == linkClick }"
                      @click="treeNavSwitch(nav)"
                      >{{ nav.text }}</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SvgIcon from '@/components/SvgIcon.vue'

export default {
  name: "Head",
  data: function () {
    return {
      show: false,
      classB: 0,
      isShow: 0,
      linkClick: "",
      configNav: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "解决方案",
          path: "",
          subItems: [
            { link: "/solutionsDetail/CloudClinic", text: "致医云诊室" },
            { link: "/solutionsDetail/CloudPharmacy", text: "致医云药房" },
            { link: "/solutionsDetail/Mall", text: "药发采" },
            { link: "/solutionsDetail/CloudHIS", text: "致医云HIS" },
            { link: "/solutionsDetail/HealthManagement", text: "致医健康管理" },
          ],
        },
        {
          name: "服务支持",
          path: "/Services",
        },
        {
          name: "新闻中心",
          path: "/News",
        },
        {
          name: "公司概况",
          path: "/about",
        },
        {
          name: "加入我们",
          path: "/Join",
        },
      ],
      searchBarFixed: false,
    };
  },
  props: {
    classA: {
      type: String,
      default: "0",
    },
    headBg: {
      type: String,
      default: "0", // 背景色：0为透明，1为蓝色，2为绿色（云药房），3为淡蓝色（致医商城），4为浅蓝色（致医云HIS），5为深蓝色（致医健康管理）
    },
  },
  methods: {
    menuselected: function (nav) {
      this.classB = nav;
    },
    showToggle: function (index) {
      this.isShow = index;
    },
    treeNavSwitch: function (nav) {
      this.linkClick = nav.link;
    },
    handleScroll() {
      const scrollTop =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const offsetTop = document.querySelector("#head").offsetTop;
      if (scrollTop > offsetTop) {
        this.searchBarFixed = true;
      } else {
        this.searchBarFixed = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.headWrap {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 70px;
  top: 0;
}
.toFixed {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 70px;
  background: linear-gradient(161deg, #0052d9 0%, #2213de 51%, #2b1ecd 100%);
  .head_wrap {
    width: 100%;
    height: 70px;
  }
}
.head_wrapBlue {
  background: linear-gradient(
    161deg,
    rgba(0, 82, 217, 1) 0%,
    rgba(34, 19, 222, 1) 51%,
    rgba(43, 30, 205, 1) 100%
  );
}
.toFixed.head_wrapGreen {
  background: linear-gradient(
    125deg,
    rgba(110, 238, 193, 1) 0%,
    rgba(0, 226, 172, 1) 100%
  );
}
.toFixed.head_wrapMall {
  background: rgba(0, 139, 255, 1);
}
.toFixed.head_wrapHIS {
  background: rgba(0, 213, 224, 1);
}
.toFixed.head_wrapHealth {
  background: linear-gradient(
    310deg,
    rgba(49, 10, 186, 1) 0%,
    rgba(98, 54, 255, 1) 100%
  );
}
.head_wrap {
  width: 100%;
  height: 70px;
  background: none;
  .head-con {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .logoDiv {
      height: 70px;
      display: flex;
      align-items: center;
      text-align: left;
      img {
        width: 50%;
      }
    }
    .nav {
      .nav-a {
        .nav-a-ul {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .nav-a-li {
            margin-left: 58px;
            position: relative;
            line-height: 70px;
            .nav-a-text {
              font-size: 14px;
              color: #fff;
              display: block;
              // padding: 22px 0 24px 0;
            }
            .iconArrow {
              display: inline-block;
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
              -webkit-transition: -webkit-transform 0.5s;
              transition: -webkit-transform 0.5s;
              transition: transform 0.5s;
              transition: transform 0.5s, -webkit-transform 0.5s;
              background: url("../../assets/images/aT2.png") no-repeat;
              background-size: 100%;
              width: 10px;
              height: 6px;
              position: absolute;
              bottom: 32px;
              right: -18px;
            }
            .menu_ul {
              display: none;
              background: rgba(255, 255, 255, 1);
              box-shadow: 5px 10px 30px 0px rgba(41, 26, 204, 0.12);
              width: 140px;
              z-index: 999;
              position: absolute;
              top: 70px;
              left: 50%;
              margin-left: -65px;
              .menu_li {
                padding: 0 23px;
                .menu_ul_text {
                  font-size: 14px;
                  color: #333;
                  letter-spacing: 0;
                  line-height: 30px;
                  text-decoration: none;
                  display: block;
                }
                .menu_ul_text:hover {
                  color: #2213de;
                }
              }
              .menu_li.active {
                .menu_ul_text {
                  color: #2213de;
                }
              }
              .menu_li:first-child {
                padding-top: 10px;
              }
              .menu_li:last-child {
                padding-bottom: 10px;
              }
            }
          }
          .nav-a-li:hover::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 4px;
            background: #f8f8f8;
            bottom: -1px;
            left: 50%;
            margin-left: -10px;
          }
          .nav-a-li:hover {
            .iconArrow {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            .menu_ul {
              display: block;
            }
          }
          .nav-a-li.active::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 4px;
            background: #f8f8f8;
            bottom: -1px;
            left: 50%;
            margin-left: -10px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .toFixed .head_wrap,
  .head_wrap {
    width: 90%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1024px) {
  .head_wrapBlue {
    background: linear-gradient(
      161deg,
      rgba(0, 82, 217, 1) 0%,
      rgba(34, 19, 222, 1) 51%,
      rgba(43, 30, 205, 1) 100%
    );
  }
  .toFixed .head_wrap,
  .head_wrap {
    width: 94%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 768px) {
  .head_wrap {
    .head-con {
      .logoDiv {
        display: none;
      }
      .nav {
        width: 100%;
        .nav-a {
          .nav-a-ul {
            .nav-a-li {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
