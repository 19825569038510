<template>
  <div :class="bgcolor === '0' ? 'foot' : 'foot footBg'">
    <div class="footWrap">
      <p>
        北京致医健康信息技术有限公司 | 联系我们：400-620-9299 |
        地址：北京经济技术开发区地泽北街1号
      </p>
      <p>
        Copyright © {{ year }} 致医健康 All Rights Reserved |
        <a href="http://beian.miit.gov.cn/" target="_blank" class="icp"
          >ICP备案号码：京ICP备17003706号</a
        >
      </p>
      <p>
        药品医疗器械网络信息服务备案编号：(京)网药械信息备字（2022）第00274号
      </p>
      <p>网络食品交易第三方平台提供者备案号：京食药网食备201910048</p>
      <p>
        <a
          target="_blank"
          class="foot-beian"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36070302361061"
          ><img
            alt="beian"
            class="foot-beian-img"
            src="@/assets/images/putOnRecordIcon.png"
          />
          <p class="foot-beian-text">京公网安备 11011502006638号</p>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  props: {
    bgcolor: {
      type: String,
      default: "0", // 背景色：0为灰色，1为白色
    },
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.footBg {
  background: #fff;
}
.foot {
  padding: 90px 0 0 0;
  .footWrap {
    width: 79.17%;
    max-width: 1140px;
    border-top: 2px solid #eee;
    text-align: center;
    padding: 40px;
    margin: 0 auto;
    box-sizing: border-box;
    p {
      font-size: 14px;
      color: #999;
      line-height: 20px;
      margin: 0;
      padding: 0;
    }
  }
  .icp {
    color: #999;
  }
}
foot-beian {
  display: inline-block;
  text-decoration: none;
  height: 20px;
  line-height: 20px;
  &-img {
    float: left;
  }
  &-text {
    float: left;
    height: 20px;
    line-height: 20px;
    margin: 0px 0px 0px 5px;
    color: #b2b2b2;
  }
}
@media screen and (max-width: 1280px) {
  .foot {
    .footWrap {
      width: 90%;
    }
  }
}
@media screen and (max-width: 1024px) {
  .foot {
    padding: 40px 0 0 0;
    .footWrap {
      width: 94%;
    }
  }
  .foot .footWrap p {
    line-height: 26px;
  }
}
</style>
