import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/Solutions',
    name: 'Solutions',
    component: () => import('../views/Solutions'),
    meta: {
      title: '解决方案'
    }
  },
  {
    path: '/Services',
    name: 'Services',
    component: () => import('../views/Services'),
    meta: {
      title: '服务'
    }
  },
  {
    path: '/News',
    name: 'News',
    component: () => import('../views/News'),
    meta: {
      title: '新闻中心'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/Join',
    name: 'Join',
    component: () => import('../views/Join'),
    meta: {
      title: '加入我们'
    }
  },
  {
    path: '/solutionsDetail/CloudClinic',
    component: () => import('../views/solutionsDetail/CloudClinic'),
    name: 'CloudClinic',
    meta: {
      title: '致医云诊室'
    }
  },
  {
    path: '/solutionsDetail/CloudPharmacy',
    component: () => import('../views/solutionsDetail/CloudPharmacy'),
    name: 'CloudPharmacy',
    meta: {
      title: '致医云药房'
    }
  },
  {
    path: '/solutionsDetail/CloudHIS',
    component: () => import('../views/solutionsDetail/CloudHIS'),
    name: 'CloudHIS',
    meta: {
      title: '致医云HIS'
    }
  },
  {
    path: '/solutionsDetail/Mall',
    component: () => import('../views/solutionsDetail/Mall'),
    name: 'Mall',
    meta: {
      title: '致医商城'
    }
  },
  {
    path: '/solutionsDetail/HealthManagement',
    component: () => import('../views/solutionsDetail/HealthManagement'),
    name: 'HealthManagement',
    meta: {
      title: '致医健康管理'
    }
  },
  {
    path: '/newsDetail/goodNews',
    component: () => import('../views/newsDetail/goodNews'),
    name: 'goodNews',
    meta: {
      title: '喜报'
    }
  },
  {
    path: '/newsDetail/healthRun',
    component: () => import('../views/newsDetail/healthRun'),
    name: 'healthRun',
    meta: {
      title: '健康跑'
    }
  },
  {
    path: '/newsDetail/userVoice12',
    component: () => import('../views/newsDetail/userVoice12'),
    name: 'userVoice12',
    meta: {
      title: '新闻中心用户心声第十二期'
    }
  },
  {
    path: '/newsDetail/userVoice11',
    component: () => import('../views/newsDetail/userVoice11'),
    name: 'userVoice11',
    meta: {
      title: '新闻中心用户心声第十一期'
    }
  },
  {
    path: '/newsDetail/userVoice10',
    component: () => import('../views/newsDetail/userVoice10'),
    name: 'userVoice10',
    meta: {
      title: '新闻中心用户心声第十期'
    }
  },
  {
    path: '/newsDetail/userVoice9',
    component: () => import('../views/newsDetail/userVoice9'),
    name: 'userVoice9',
    meta: {
      title: '新闻中心用户心声第九期'
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
